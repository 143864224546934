const colors = {
  slate: {
    default: "#00053B",
    medium: "#666989",
    soft: "#D9DAE2",
    light: "#F2F3F5",
    disabled: "#B3B4C4",
    hover: "#000219"
  },
  green: {
    default: "#76C067",
    dark: "#55A545",
    light: "#F1F9F0",
    hover: "#4A913C",
    soft: "#BBDFB3"
  },
  orange: {
    default: "#EEA848",
    dark: "#D28314",
    light: "#FDF6ED"
  },
  red: {
    default: "#E74C3C",
    light: "#FDEDEB"
  },
  blue: {
    default: "#00A0D3",
    hover: "#008BB7",
    light: "#E5F6FB"
  },
  sand: {
    default: "#F7F6F4",
    hover: "#EDECEA"
  }
};
const screens = {
  xs: "360px",
  sm: "640px",
  md: "800px",
  lg: "1024px",
  xl: "1400px",
  "2xl": "1920px"
};

const remotesUrlMap = {
  ["headerFederatedModule" /* Header */]: {
    dev: "http://localhost:3300",
    prod: "/mfe-remotes/header"
  }
};
const remoteFederatedModules = ["headerFederatedModule" /* Header */];
const remotes = remoteFederatedModules.reduce((remotes2, currentRemote) => {
  const isProductionBuild = process.env.NODE_ENV === "production";
  const { dev, prod } = remotesUrlMap[currentRemote];
  const currentEpoch = Date.now();
  const remoteUrl = isProductionBuild ? prod : dev;
  remotes2[currentRemote] = `${currentRemote}@${remoteUrl}/remoteEntry.js?${currentEpoch}`;
  return remotes2;
}, {});

export { colors, remotes, screens };
