import { computed } from 'vue'

export interface BaseIcon {
  size?: string
  width?: string
  height?: string
  color?: string
}

export const defaultIconProps: BaseIcon = {
  color: '#00053B',
  size: '20px',
  width: undefined,
  height: undefined
}

export function useIcon(props: BaseIcon) {
  const iconWidth = computed(() => {
    if (props.width) return props.width
    else return props.size
  })

  const iconHeight = computed(() => {
    if (props.height) return props.height
    else return props.size
  })

  const iconColor = computed(() => {
    return props.color
  })

  return { iconColor, iconWidth, iconHeight }
}
